import React from "react";
import FeatureImage from "../../images/feature-highlight.svg";
import {
  Background,
  Container,
  Message,
  Header,
  Description,
  Image,
  ImageContainer,
  WhiteTypography
} from "./styles";

const FeatureHighlight = ({ location }) => {
  return (
    <Background>
      <Container>
        <Message>
          <Header>
            <WhiteTypography variant="h2">
              Deliver success like never before
            </WhiteTypography>
          </Header>
          <Description>
            <WhiteTypography variant="body">
              Outline is the first Customer Success platform that works the way
              you work. Prioritize, manage, and engage your customers all from
              one, easy-to-use platform (finally).
            </WhiteTypography>
          </Description>
        </Message>
        <ImageContainer>
          <Image src={FeatureImage} />
        </ImageContainer>
      </Container>
    </Background>
  );
};

export default FeatureHighlight;
