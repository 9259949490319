import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Section from "../../components/Section";

export const FeatureSet = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled(Section)`
  min-height: 70vh;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.large}) {
    min-height: 60vh;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
  }
`;

export const Message = styled.div`
  text-align: center;
  width: 50%;

  @media (max-width: ${breakpoints.large}) {
    width: 75%;
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
  }
`;

export const Description = styled.div`
  text-align: center;
  color: ${colors.greyDark};
  font-size: 16px;
`;

export const FeatureRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    width: 75%;
  }
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
  border-radius: 1rem;
  padding: 2rem;
  width: 25%;

  @media (max-width: ${breakpoints.large}) {
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 1rem;
    margin: 0.5rem;
  }
`;

export const Image = styled.img`
  margin-bottom: 2rem;
  height: 9rem;

  @media (max-width: ${breakpoints.medium}) {
    margin-bottom: 1rem;
  }
`;

export const FeatureBlock = styled.div`
  color: ${colors.black};

  @media (max-width: ${breakpoints.medium}) {
    padding-left: 0rem;
    text-align: center;
  }
`;
export const FeatureTitle = styled.div`
  color: ${colors.black};
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 18px;
`;
