import React from "react";
import Typography from "../Typography";
import {
  Header,
  HeaderText,
  Description,
  SketchUnderline,
  HeroSecondary as HeroSecondaryStyled
} from "./styles";
import Section from "../../components/Section";

const HeroSecondary = ({ location }) => {
  return (
    <Section>
      <HeroSecondaryStyled>
        {/*	<Image src={Image1}/>*/}
        <Header>
          <HeaderText variant="h2">One, prioritized workspace.</HeaderText>
        </Header>
        <Description>
          <Typography variant="body">
            Capture customer needs and feedback across any channel, coordinate
            with teammates in real-time, and proactively close the loop with
            customers —{" "}
            <SketchUnderline>all from a single platform.</SketchUnderline>
          </Typography>
        </Description>

        {/*
      <OverviewContainer>
        <Overview>
          <OverviewText>
            <Typography variant = "body">Capture</Typography>
          </OverviewText>
        </Overview>
        <Overview>
          <OverviewText>
            <Typography variant = "body">Capture</Typography>
          </OverviewText>
        </Overview>
        <Overview>
          <OverviewText>
            <Typography variant = "body">Capture</Typography>
          </OverviewText>
        </Overview>
      </OverviewContainer>*/}
      </HeroSecondaryStyled>
    </Section>
  );
};

export default HeroSecondary;
