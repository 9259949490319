import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Section from "../../components/Section";
import BackgroundImage from "../../images/feature-highlight-background.svg";
import Typography from "../Typography";

export const Background = styled.div`
  min-height: 80vh;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;

  @media (max-width: ${breakpoints.large}) {
    min-height: 70vh;
  }
`;

export const Container = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  @media (max-width: ${breakpoints.large}) {
    justify-content: center;
    text-align: center;
  }

  @media (max-width: ${breakpoints.medium}) {
    min-height: 65vh;
  }
`;

export const Message = styled.div`
  wrap: no-wrap;
  max-width: 450px;
`;

export const Header = styled.div`
  color: ${colors.white};
`;

export const Description = styled.div`
  color: ${colors.white};
  opacity: 0.75;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;

  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const Image = styled.img`
  max-width: none;

  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const WhiteTypography = styled(Typography)`
  color: ${colors.white};
`;
