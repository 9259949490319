import React from "react";
import { navigate } from "gatsby";
import {
  Container,
  Description,
  MessageContainer,
  ImageMaxWidth
} from "./styles";
import Button from "../Button";
import Typography from "../Typography";
import Img from "gatsby-image";

const Hero = ({ location, fluid }) => {
  return (
    <Container shrinkTop>
      <MessageContainer>
        <Typography variant="h1">
          Put your customers at the center of everything
        </Typography>
        <Description>
          Outline makes it simple to manage and engage customers, align
          cross-functional teammates, and deliver unified success at scale.
        </Description>
        <Button onClick={() => navigate("/demo")} variant="primary">
          Join the waitlist
        </Button>
      </MessageContainer>
      <ImageMaxWidth>
        <Img fluid={fluid} />
      </ImageMaxWidth>
    </Container>
  );
};

export default Hero;
