import React from "react";
import {
  Container,
  Message,
  Feature,
  FeatureRow,
  FeatureBlock,
  FeatureTitle,
  Image,
  Description,
  FeatureSet as StyledFeatureSet
} from "./styles";
import Feature1 from "../../images/feature-1.svg";
import Feature2 from "../../images/feature-2.svg";
import Feature3 from "../../images/feature-3.svg";
import Feature4 from "../../images/feature-4.svg";
import Feature5 from "../../images/feature-5.svg";
import Feature6 from "../../images/feature-6.svg";
import Typography from "../Typography";

const FeatureSet = () => {
  return (
    <Container>
      <StyledFeatureSet>
        <Message>
          <Typography variant="h2">
            Powerful customer management at scale
          </Typography>
        </Message>

        <FeatureRow>
          <Feature>
            <Image src={Feature1} />
            <FeatureBlock>
              <FeatureTitle>Source of truth</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Customer activity, notes, and tasks in one, unified view.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
          <Feature>
            <Image src={Feature2} />
            <FeatureBlock>
              <FeatureTitle>Cross-team collaboration</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Easily align internal teammates around shared customer needs.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
          <Feature>
            <Image src={Feature3} />
            <FeatureBlock>
              <FeatureTitle>Customer insights</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Automatically know who to engage, when, and with what context.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
        </FeatureRow>
        <FeatureRow>
          <Feature>
            <Image src={Feature4} />
            <FeatureBlock>
              <FeatureTitle>Product roadmap</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Aggregated views of customer needs and feedback.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
          <Feature>
            <Image src={Feature5} />
            <FeatureBlock>
              <FeatureTitle>Workflow productivity</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Uplevel your workflow and spend more time doing what matters.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
          <Feature>
            <Image src={Feature6} />
            <FeatureBlock>
              <FeatureTitle>Proactive engagement</FeatureTitle>
              <Description>
                <Typography variant="body">
                  Close the loop with customers directly from Outline.
                </Typography>
              </Description>
            </FeatureBlock>
          </Feature>
        </FeatureRow>
      </StyledFeatureSet>
    </Container>
  );
};

export default FeatureSet;
