import React from "react";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import HeroSecondary from "../components/HeroSecondary";
import FeatureSet from "../components/FeatureSet";
import FeatureHighlight from "../components/FeatureHighlight";
// import Blog from "../components/Blog";
import CTA from "../components/CTA";
import "normalize.css";
import { Outer } from "./styles";

const IndexPage = ({ location, fluid }) => (
  <Outer>
    <SEO />
    <Hero location={location} fluid={fluid} />
    <HeroSecondary />
    <FeatureSet />
    <FeatureHighlight />
    {/*  <Blog />*/}
    <CTA location={location} />
  </Outer>
);

export default IndexPage;
