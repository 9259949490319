import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Section from "../../components/Section";

const IMAGE_BREAKPOINT = "875px";

export const Container = styled(Section)`
  flex-direction: row;
`;

export const MessageContainer = styled.div`
  max-width: 550px;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: flex-start;
  padding-right: 4rem;

  @media (max-width: ${IMAGE_BREAKPOINT}) {
    padding-right: 0;
    align-items: center;
    text-align: center;
  }
`;

export const Description = styled.div`
  color: ${colors.greyDark};
  font-size: 20px;
  margin-bottom: 3rem;
  max-width: 450px;

  @media (max-width: ${breakpoints.medium}) {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  @media (max-width: ${breakpoints.small}) {
    font-size: 19px;
  }
`;

export const ImageMaxWidth = styled.div`
  overflow: hidden;
  white-space: nowrap;
  flex: 1;

  @media (max-width: ${IMAGE_BREAKPOINT}) {
    display: none;
  }
`;
