import React from "react";
import IndexScreen from "../screens";

const Index = ({
  data: { file: { childImageSharp: { fluid } = {} } = {} },
}) => {
  return <IndexScreen fluid={fluid} />;
};

export const query = graphql`
  query {
    file(relativePath: { eq: "hero-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 740) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default Index;
