import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import SketchImage from "../../images/sketch-underline.svg";
import Typography from "../Typography";

export const HeroSecondary = styled.div`
  background-color: ${colors.greyBackground};
  text-align: left;

  padding: 8rem;
  border-radius: 1rem;

  display:flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  @media (max-width: ${breakpoints.large}) {
    padding: 6rem;
    flex-direction: column;
    text-align: center;

  @media (max-width: ${breakpoints.small}) {
    padding: 3rem;
  }
`;

export const Header = styled.div`
  overflow-wrap: normal;
  flex: 1;
`;

export const HeaderText = styled(Typography)`
  margin: 0;

  @media (max-width: ${breakpoints.large}) {
    margin: 1.67rem;
  }
`;

export const Description = styled.div`
  flex: 2;
  max-width: 600px;
  margin-left: 4rem;

  @media (max-width: ${breakpoints.large}) {
    margin-left: 0rem;
  }
`;

export const SketchUnderline = styled.span`
  background: url(${SketchImage}) bottom left no-repeat;
  background-size: 100% 1rem;
  padding-bottom: .75rem;
  color: ${colors.primary};
}
`;

export const Image = styled.img`
  margin-bottom: 3rem;
`;

// export const OverviewContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 4rem;
// `

// export const Overview = styled.div`
//   padding: 2rem;
//   background-color: ${colors.greyLightest};
//   border-radius: .5rem;
//   margin: 10%;
// `
// export const OverviewText = styled.div`
//   color: ${colors.black}
// `
